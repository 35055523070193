import img1 from "../assets/images/thumbnail/0.png";
import img2 from "../assets/images/thumbnail/1.png";
import img3 from "../assets/images/thumbnail/2.1.png";
import img4 from "../assets/images/thumbnail/3.png";
import img5 from "../assets/images/thumbnail/4.png";
import img6 from "../assets/images/thumbnail/5.png";
import img7 from "../assets/images/thumbnail/6.png";
import img8 from "../assets/images/thumbnail/8.png";
import img11 from "../assets/images/thumbnail/11.png";
import img12 from "../assets/images/thumbnail/12.png";
import img13 from "../assets/images/thumbnail/7.png";
const allData = [
  {
    id: 1,
    img: img1,
    title: {
      bn: "নোটিশ",
      en: "Notice",
    },

    sub: [
      {
        id: 3,
        name: {
          bn: "ব্লাড (স্যাম্পল) কালেকশনঃ সকাল ৮:০০ টা থেকে দুপুর ১২:০০ টা",
          en: "Blood (Sample) Collection: From 08.00 AM to 12.00 PM",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=4",
      },
      {
        id: 1,
        name: {
          bn: "রিপোর্ট ডেলিভারি সময়ঃ দুপুর ১২:০০ টা থেকে বেলা ২:৩০ মিনিট",
          en: "Report Delivery Time: From 12.00 PM to 02.30 PM",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=6",
      },
      {
        id: 2,
        name: {
          bn: "অফিস সময়ঃ সকাল ৮:০০ টা থেকে দুপুর ২:৩০ মিনিট",
          en: "Office Time: From 08.00 AM to 02.30 PM",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=5",
      },
    ],
  },
  {
    id: 3,
    img: img3,
    title: {
      bn: "রোগীর পোর্টাল",
      en: "Patient Portal",
    },

    sub: [
      {
        id: 11,
        name: {
          bn: "রিপোর্ট ডেলিভারি",
          en: "Report Delivery",
        },
        link: "",
      },
      {
        id: 21,
        name: {
          bn: "টেস্ট/পরীক্ষার আপডেট মূল্য তালিকা",
          en: "Updated Price List of Test's/Exam's",
        },
        link: "",
      },
      {
        id: 31,
        name: {
          bn: "বিশেষ পরীক্ষার নির্দেশনা (পূর্ব প্রস্তুতি)",
          en: "Special Exam Instructions (Pre-Preparation)",
        },
        link: "",
      },
    ],
  },
  {
    id: 2,
    img: img2,
    title: {
      bn: "আমাদের সম্পর্কে",
      en: "About Us",
    },

    sub: [
      {
        id: 10,
        name: {
          bn: "মিশন ও ভিশন",
          en: "Mission & Vision",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=6",
      },
      {
        id: 20,
        name: {
          bn: "বিভাগ সমূহ",
          en: "Departments",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=5",
      },
      {
        id: 30,
        name: {
          bn: "যোগাযোগ",
          en: "Contact",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=4",
      },
      {
        id: 40,
        name: {
          bn: "সাংগঠনিক কাঠামো/অর্গানোগ্রাম",
          en: "Organizational Structure/Organogram",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=4",
      },
      {
        id: 50,
        name: {
          bn: "মানব সম্পদ ডাটাবেজ",
          en: "Human Resources Database",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=4",
      },
      {
        id: 60,
        name: {
          bn: "পরীক্ষা সমূহ",
          en: "Tests",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=4",
      },

      {
        id: 80,
        name: {
          bn: "দুর্লভ ও বিশেষায়িত পরীক্ষা",
          en: "Rare and Specialized Tests",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=4",
      },
      {
        id: 70,
        name: {
          bn: "এক্রিডিটেশন (চলমান)",
          en: "Accreditation (Ongoing)",
        },
        link: "https://nilmrc.hitechit.com.bd/view-notice.php?page_id=4",
      },
    ],
  },

  {
    id: 4,
    img: img4,
    title: {
      bn: "অফিস ব্যবস্থাপনা",
      en: "Office management",
    },

    sub: [
      {
        id: 15,
        name: {
          bn: "ই-ফাইলিং সিস্টেম",
          en: "e-Filing Stytem",
        },
        link: "",
      },
      {
        id: 25,
        name: {
          bn: "ই-টেন্ডারিং",
          en: "e-Tendering",
        },
        link: "",
      },
      {
        id: 35,
        name: {
          bn: "অফিস আদেশ",
          en: "Office Order",
        },
        link: "",
      },
      {
        id: 45,
        name: {
          bn: "ডিউটি রোস্টার",
          en: "Duty Roster",
        },
        link: "",
      },
      {
        id: 55,
        name: {
          bn: "হোয়্যাটসঅ্যাপ গ্রুপ",
          en: "WhatsApp Group",
        },
        link: "",
      },
      {
        id: 65,
        name: {
          bn: "বর্জ্য ব্যবস্থাপনা",
          en: "Waste Management",
        },
        link: "",
      },
      {
        id: 75,
        name: {
          bn: "দাপ্তরিক",
          en: "Official",
        },
        link: "",
      },
    ],
  },
  {
    id: 5,
    img: img7,
    title: {
      bn: "সরকারি বিভিন্ন আদেশ",
      en: "Various Government Orders",
    },

    sub: [
      {
        id: 29,
        name: {
          bn: "প্রশাসনিক কার্যক্রম",
          en: "Administrative Activities",
        },
        link: "",
      },
      {
        id: 39,
        name: {
          bn: "সার্কুলার - প্রশাসন বিষয়ক",
          en: "Circular - Administration Matters",
        },
        link: "",
      },
      {
        id: 49,
        name: {
          bn: "টেন্ডার নোটিশ",
          en: "Tender Notice",
        },
        link: "",
      },
      {
        id: 59,
        name: {
          bn: "এনওসি",
          en: "NOC",
        },
        link: "",
      },
      {
        id: 69,
        name: {
          bn: "পরিপত্র - প্রজ্ঞাপন",
          en: "Circular - Notification",
        },
        link: "",
      },
      {
        id: 79,
        name: {
          bn: "বহি: বাংলাদেশ ছুটি",
          en: "Holiday Abroad Bangladesh",
        },
        link: "",
      },
      {
        id: 89,
        name: {
          bn: "অর্জিত ছুটি",
          en: "Earned Leave",
        },
        link: "",
      },
      {
        id: 99,
        name: {
          bn: "বিজ্ঞপ্তি",
          en: "Notice",
        },
        link: "",
      },
      {
        id: 109,
        name: {
          bn: "পিআরএল",
          en: "PRL",
        },
        link: "",
      },
      // {
      //   id: 49,
      //   name: {
      //     bn: "বিসিএস হেলথ/হেলথ সার্ভিস",
      //     en: "BCS Health/Health Service",
      //   },
      //   link: "",
      // },
    ],
  },

  {
    id: 7,
    img: img8,
    title: {
      bn: "কর্মকর্তা/কর্মচারীদের তালিকা",
      en: "List of Officers/Employees",
    },

    sub: [
      {
        id: 122,
        name: {
          bn: "সকল কর্মকর্তা/কর্মচারীদের সংখ্যা",
          en: "Total Number of Officers/Employees",
        },
        link: "",
      },
      {
        id: 322,
        name: {
          bn: "সকল কর্মকর্তা/কর্মচারী",
          en: "All Officers/Employees",
        },
        link: "",
      },
      {
        id: 222,
        name: {
          bn: "চিকিৎসক",
          en: "Doctor",
        },
        link: "",
      },

      {
        id: 422,
        name: {
          bn: "মেডিকেল টেকনোলজিস্ট",
          en: "Medical Technologist",
        },
        link: "",
      },
      {
        id: 522,
        name: {
          bn: "আউটসোর্সিং",
          en: "Outsourcing",
        },
        link: "",
      },
    ],
  },
  {
    id: 6,
    img: img5,
    title: {
      bn: "ই-হেলথ",
      en: "e-Health",
    },

    sub: [
      {
        id: 111,
        name: {
          bn: "অভিযোগ পরামর্শ",
          en: "Grievance Advice",
        },
        link: "",
      },
      {
        id: 211,
        name: {
          bn: "অফিস উপস্থিতি তদারকি",
          en: "Office Attendance Monitoring",
        },
        link: "",
      },
      {
        id: 311,
        name: {
          bn: "ডায়াগনোস্টিক অটোমেশন",
          en: "Diagnostic Automation",
        },
        link: "",
      },
      {
        id: 411,
        name: {
          bn: "কিউ ম্যানেজমেন্ট",
          en: "Queue Management",
        },
        link: "",
      },
      {
        id: 511,
        name: {
          bn: "অফিস অটোমেশন",
          en: "Office Automation",
        },
        link: "",
      },
    ],
  },
  {
    id: 8,
    img: img13,
    title: {
      bn: "ফরম সমূহ",
      en: "Forms",
    },

    sub: [
      {
        id: 333,
        name: {
          bn: "ছুটির ফরম",
          en: "Leave Form",
        },
        link: "",
      },
      // {
      //   id: 133,
      //   name: {
      //     bn: "ফ্রি ফরম",
      //     en: "Free Form",
      //   },
      //   link: "",
      // },
      {
        id: 233,
        name: {
          bn: "এনওসি ফরম",
          en: "NOC Form",
        },
        link: "",
      },

      {
        id: 433,
        name: {
          bn: "অভিযোগ ফরম",
          en: "Complaint Form",
        },
        link: "",
      },
    ],
  },
  {
    id: 9,
    img: img6,
    title: {
      bn: "অভিযোগ প্রতিকার ব্যবস্থাপনা",
      en: "Grievance Redressal Management",
    },

    sub: [
      {
        id: 155,
        name: {
          bn: "সাপ্তাহিক/মাসিক/ত্রৈমাসিক/বার্ষিক পরীক্ষণ/মূল্যায়ণ প্রতিবেদন",
          en: "Weekly/Monthly\n/Quarterly/Annual Inspection/Evaluation Report",
        },
        link: "",
      },
      {
        id: 255,
        name: {
          bn: "অভিযোগ দাখিল (অনলাইন আবেদন)",
          en: "Complaint Filing (Online Application)",
        },
        link: "",
      },
      {
        id: 355,
        name: {
          bn: "আইন/বিধি/নীতিমালা/পরিপত্র/নির্দেশিকা/প্রজ্ঞাপন",
          en: "Laws/Rules/Policies \n/Circulars/Guidelines \n/Notifications",
        },
        link: "",
      },
    ],
  },
  {
    id: 10,
    img: img11,
    title: {
      bn: "আইসিটি",
      en: "ICT",
    },

    sub: [
      {
        id: 166,
        name: {
          bn: "আইসিটি ফোকাল পার্সন গাইড লাইন",
          en: "ICT Focal Person Guideline",
        },
        link: "",
      },
      {
        id: 266,
        name: {
          bn: "জাতীয় আইসিটি নীতিমালা",
          en: "National ICT Policy",
        },
        link: "",
      },
      {
        id: 366,
        name: {
          bn: "কম্পিউটার প্রশিক্ষণ সহায়িকা",
          en: "Computer Training Aid",
        },
        link: "",
      },
    ],
  },
  {
    id: 11,
    img: img12,
    title: {
      bn: "স্বাস্থ্য নীতিমালা ও গাইড লাইন",
      en: "Health Principles and Guidelines",
    },

    sub: [
      {
        id: 177,
        name: {
          bn: "জাতীয় স্বাস্থ্য নীতি",
          en: "National Health Policy",
        },
        link: "",
      },
      {
        id: 277,
        name: {
          bn: "স্বাস্থ্য নিরাপত্তা আইন",
          en: "Health Security Act",
        },
        link: "",
      },
      {
        id: 377,
        name: {
          bn: "হেলথ বুলেটিন",
          en: "Health Bulletin",
        },
        link: "",
      },
      {
        id: 477,
        name: {
          bn: "কোভিড-১৯ সংক্রান্ত তথ্য",
          en: "Covid-19 Information",
        },
        link: "",
      },
      {
        id: 577,
        name: {
          bn: "ডেঙ্গু পকেট গাইড-২০২৩",
          en: "Dengue Pocket Guide-2023",
        },
        link: "",
      },
    ],
  },
  //  {
  //   id:9,
  //   img: img12,
  //   title: "প্রয়োজনীয় লিঙ্ক",
  //   icon: <MdPlayArrow />,
  //   icon2: <MdPlayArrow />,
  //   icon3: <MdPlayArrow />,
  //   icon4: "",
  //   icon5: "",
  //   icon6: "",
  //   icon7: "",
  //   sub: "সামাজিক যোগাযোগ আইকন",
  //   link: "",
  //   sub2: "আমাদের সাথে যোগাযোগ",
  //   link2: "",
  //   sub3: "জরুরী হটলাইন",
  //   link3: "",
  //   sub4: "",
  //   link4: "",
  //   sub5: "",
  //   link5: "",
  //   sub6: "",
  //   link6: "",
  //   sub7: "",
  //   link7: "",

  // },

  // {
  //   id:3,
  //   img: img3,
  //   title: "প্যাশেন্ট পোর্টাল",
  //   icon: <MdPlayArrow />,
  //   icon2: <MdPlayArrow />,
  //   icon3: "",
  //   icon4: "",
  //   icon5: "",
  //   icon6: "",
  //   icon7: "",
  //   sub: "রিপোর্ট ডেলিভারি",
  //   link: "",
  //   sub2: "টেস্ট/পরীক্ষার আপডেট মূল্য তালিকা",
  //   link2: "",
  //   sub3: "",
  //   link3: "",
  //   sub4: "",
  //   link4: "",
  //   sub5: "",
  //   link5: "",
  //   sub6: "",
  //   link6: "",
  //   sub7: "",
  //   link7: "",

  // },
];

export default allData;
