import { Button, Carousel, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo/logo.png";
import { Link, useLocation } from "react-router-dom";
import Loader from "./shared/Loader";
import { server } from "../server";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper/modules";

const Hero = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(
    localStorage.getItem("language") || "bn"
  );
  const lang = localStorage.getItem("language");
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/banner/get-banner`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const languageChange = () => {
    if (change === "en") {
      localStorage.setItem("language", "bn");
      setChange("bn");
    } else {
      localStorage.setItem("language", "en");
      setChange("en");
    }

    window.location.reload();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div>
        {change === "bn" ? (
          <Button
            className="rounded-sm my-2"
            color="green"
            size="sm"
            onClick={languageChange}
          >
            English
          </Button>
        ) : (
          <Button
            className="rounded-sm my-2"
            color="green"
            size="sm"
            onClick={languageChange}
          >
            বাংলা
          </Button>
        )}
      </div>
      <Link to="/">
        <div className="flex items-center gap-4 absolute top-36 ml-8 z-10">
          <img className="w-20" src={logo} alt="" />
          <Typography
            style={{ textShadow: "5px 5px 5px black" }}
            className="font-bold text-lg lg:text-2xl text-yellow-400"
          >
            {lang === "en"
              ? data?.banner[0]?.title?.en
              : data?.banner[0]?.title?.bn}
          </Typography>
        </div>
      </Link>
      <div>
        <>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {data?.banner[0]?.images?.map((data) => (
              <SwiperSlide key={data._id}>
                <img
                  src={data?.url}
                  alt="image 1"
                  className="h-64 w-full object-cover object-top rounded-sm"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      </div>
    </div>
  );
};

export default Hero;
