import {
  Card,
  Typography,
  CardBody,
  CardFooter,
  Avatar,
  Button,
  CardHeader,
  Input,
  Tooltip,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { server } from "../../server";
import { FcDocument, FcPrint } from "react-icons/fc";
import { FaSistrix } from "react-icons/fa";

const TABLE_HEADERS = {
  en: ["Name", "Designation", "Blood Group", "ID Number"],
  bn: ["নাম", "পদবী", "রক্তের গ্রুপ", "আইডি নং"],
};

const Doctor = ({ printableContent }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const lang = localStorage.getItem("language");

  const [searchQuery, setSearchQuery] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    filterData(value);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/staffInfo/get-staffinfo`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.staffInfo);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  data.sort(function (a, b) {
    return a.serial - b.serial;
  });
  const doctor = data.filter((d) => d.name.en.toLowerCase().includes("dr."));

  const filterData = (query) => {
    const filtered = doctor.filter(
      (item) =>
        item.name.en.toLowerCase().includes(query.toLowerCase()) ||
        item.name.bn.toLowerCase().includes(query.toLowerCase()) ||
        item.designation.en.toLowerCase().includes(query.toLowerCase()) ||
        item.designation.bn.toLowerCase().includes(query.toLowerCase()) ||
        item.bloodGroup.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const totalItems = doctor.length;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = [];
  if (filteredData.length > 0) {
    currentItems = filteredData;
  } else if (doctor.length < 10) {
    currentItems = doctor;
  } else {
    currentItems = doctor.slice(indexOfFirstItem, indexOfLastItem);
  }
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Printing

  const Print = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  // Excel
  const exportToExcel = () => {
    // Transform the data into a flat structure for export
    const flattenedData = doctor.map(
      ({ name, designation, bloodGroup, idNo, phoneNumber }) => ({
        Name: lang === "en" ? name.en : name.bn,
        Designation: lang === "en" ? designation.en : designation.bn,
        "Blood Group": bloodGroup,
        "Phone Number": phoneNumber.en,
        "ID Number": idNo,
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Doctors");

    const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Doctors.xlsx";
    a.click();
    URL.revokeObjectURL(url);
  };

  const convertToBengaliNumerals = (number) => {
    const arabicNumerals = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const bengaliNumerals = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

    return number
      .toString()
      .replace(
        /\d/g,
        (digit) => bengaliNumerals[arabicNumerals.indexOf(digit)]
      );
  };

  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Doctor" : "চিকিৎসক"}
          </Typography>
        </Card>
        {currentItems?.length > 0 && (
          <Card className="h-full w-full rounded-sm mt-2">
            <CardHeader floated={false} shadow={false} className="rounded-none">
              <div className=" flex items-center justify-between mt-1">
                <div className="md:w-96 w-72">
                  <Input
                    label="Name, Designation, Blood Group"
                    icon={<FaSistrix className="h-5 w-5" />}
                    value={searchQuery}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    color="white"
                    onClick={Print}
                    className=""
                  >
                    <FcPrint className="text-4xl" />
                  </button>
                  <button type="button" color="white" onClick={exportToExcel}>
                    <FcDocument className="text-4xl" />
                  </button>
                </div>
              </div>
            </CardHeader>
            <p className="mx-5 mt-3 font-bold text-green-600">
              {lang === "en"
                ? `Total ${doctor?.length} people`
                : `মোট ${convertToBengaliNumerals(doctor?.length)} জন`}
            </p>
            <div id="printablediv">
              <CardBody className="overflow-scroll px-0 no-scrollbar">
                <table className="w-full  text-left">
                  <thead>
                    <tr>
                    {TABLE_HEADERS[lang || "en"]?.map((head, index) => (
                        <th
                          key={index}
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.map(
                      (
                        {
                          avatar,
                          bloodGroup,
                          designation,
                          idNo,
                          name,
                          phoneNumber,
                        },
                        index
                      ) => {
                        const isLast = index === data.length - 1;
                        const classes = isLast
                          ? "p-4"
                          : "p-4 border-b border-blue-gray-50";
                        return (
                          <tr key={name}>
                            <td className={classes}>
                              <div className="flex items-center justify-center gap-3">
                                <Avatar
                                  src={avatar?.url}
                                  alt={name?.bn}
                                  size="md"
                                  className="border border-blue-gray-50 w-16 h-16 object-contain p-0"
                                />
                              </div>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-center  capitalize"
                              >
                                {lang === "en"
                                  ? name?.en
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")
                                  : name?.bn}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {lang === "en"
                                  ? designation?.en
                                  : designation?.bn}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="red"
                                className="font-normal"
                              >
                                {bloodGroup}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {idNo}
                              </Typography>
                            </td>
                            {/* <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {lang === 'en' ? phoneNumber.en : phoneNumber.bn}
                    </Typography>
                  </td> */}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </CardBody>
            </div>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Button
                variant="outlined"
                size="sm"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {lang === "en" ? "Previous" : "পূর্ববর্তী"}
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {lang === "en" ? "Next" : "পরবর্তী"}
              </Button>
            </CardFooter>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Doctor;
