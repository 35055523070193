import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

const allData = [
  {
    en: "Department of Administration",
    bn: "প্রশাসনিক বিভাগ",
    link: "/department-of-administration",
  },
  {
    en: "Department of Biochemistry",
    bn: "বায়োকেমিস্ট্রি বিভাগ",
    link: "/department-of-biochemistry",
  },
  {
    en: "Department of Microbiology",
    bn: "মাইক্রোবায়োলজি বিভাগ",
    link: "/department-of-microbiology",
  },
  {
    en: "Department of Hematology",
    bn: "হেমাটোলজি বিভাগ",
    link: "/department-of-hematology",
  },
  {
    en: "Department of Pathology",
    bn: "প্যাথলজি বিভাগ",
    link: "/department-of-pathology",
  },
  {
    en: "Department of Clinical Pathology",
    bn: "ক্লিনিক্যাল প্যাথলজি বিভাগ",
    link: "/department-of-clinical-pathology",
  },
  {
    en: "Department of Virology",
    bn: "ভাইরোলজি বিভাগ",
    link: "/department-of-virology",
  },
  {
    en: "Department of Radiology and Imaging",
    bn: "রেডিওলজি এন্ড ইমেজিং বিভাগ",
    link: "/department-of-radiology-and-imaging",
  },
  {
    en: "Department of Transfusion",
    bn: "ট্রান্সফিউশন বিভাগ",
    link: "/department-of-transfusion",
  },
];

const Departments = () => {
  const lang = localStorage.getItem("language");
  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Departments" : "বিভাগসমূহ"}
          </Typography>
        </Card>
      </div>
      <Card className="grid grid-cols-2 gap-4 rounded-sm p-4">
        {allData.map((data, index) => {
          return (
            <div key={index}>
              <Link to={data.link}>
                <Card className="lg:h-20 h-28 rounded-sm flex justify-center items-center hover:rounded-2xl  hover:drop-shadow-[0px_0px_6px_rgba(217,10,0,0.30)] hover:bg-green-400 transition-all group lg:hover:scale-105">
                  <Typography className="group-hover:text-white text-black">
                    {lang === "en" ? data.en : data.bn}
                  </Typography>
                </Card>
              </Link>
            </div>
          );
        })}
      </Card>
    </div>
  );
};

export default Departments;
