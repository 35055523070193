import React from "react";
import Hero from "../components/Hero";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "../components/shared/Footer";
import { Header } from "../components/shared/Header";
import RightNav from "../components/shared/RightNav";
import LeftNav from "../components/shared/LeftNav";
import RightNav2 from "../components/shared/RightNav2";
import LeftNav2 from "../components/shared/LeftNav2";

const Main = () => {
  return (
    <div className="max-w-5xl mx-auto px-1 lg:px-2">
      <Hero />
      <Header />

      <div className="flex flex-col gap-8 lg:gap-0 items-center lg:items-start lg:flex-row justify-center mx-auto lg:justify-between my-0">
        <div className="order-3 lg:order-none w-full lg:w-48">
          <RightNav />
          <span className="hidden lg:block">
            <LeftNav2 />
          </span>
        </div>
        <div className="order-5 lg:order-none visible lg:hidden w-full">
          <LeftNav2 />
        </div>
        <div className="order-1 lg:order-none w-full">
          <Outlet />
        </div>
        <div className="order-2 lg:order-none w-full lg:w-48">
          <LeftNav />
          <span className="hidden lg:block ">
            <RightNav2 />
          </span>
        </div>
        <div className="order-4 lg:order-none visible lg:hidden w-full">
          <RightNav2 />
        </div>
      </div>
      <Footer />
      <ScrollRestoration />
    </div>
  );
};

export default Main;
