import About from "../pages/About";
import Contact from "../pages/Contact";
import CurrentAffairs from "../pages/CurrentAffairs";
import GalleryDetails from "../pages/GalleryDetails";
import Homepage from "../pages/Homepage";
import ICT from "../pages/ICT";
import Journal from "../pages/Journal";
import NOC from "../pages/NOC";
import Notice from "../pages/Notice";
import PhotoGallery from "../pages/PhotoGallery";
import Subject from "../pages/Subject";
import Test from "../pages/Test";
import VideoGallery from "../pages/VideoGallery";
import Main from "./Main";
import EmergencyNotice from "../pages/EmergencyNotice";
import VideoGalleryDetails from "../pages/VideoGalleryDetails";
import People from "../components/People";
import ErrorPage from "../components/shared/ErrorPage";
import Administration from "../pages/departments/Administration";
import Biochemistry from "../pages/departments/Biochemistry";
import Microbiology from "../pages/departments/Microbiology";
import Hematology from "../pages/departments/Hematology";
import Pathology from "../pages/departments/Pathology";
import ClinicalPathology from "../pages/departments/ClinicalPathology";
import Virology from "../pages/departments/Virology";
import RadiologyAndImaging from "../pages/departments/RadiologyAndImaging";
import Transfusion from "../pages/departments/Transfusion";

const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/noc",
        element: <NOC />,
      },
      {
        path: "/department-of-administration",
        element: <Administration />,
      },
      {
        path: "/department-of-biochemistry",
        element: <Biochemistry />,
      },
      {
        path: "/department-of-microbiology",
        element: <Microbiology />,
      },
      {
        path: "/department-of-hematology",
        element: <Hematology />,
      },
      {
        path: "/department-of-pathology",
        element: <Pathology />,
      },
      {
        path: "/department-of-clinical-pathology",
        element: <ClinicalPathology />,
      },
      {
        path: "/department-of-virology",
        element: <Virology />,
      },
      {
        path: "/department-of-radiology-and-imaging",
        element: <RadiologyAndImaging />,
      },
      {
        path: "/department-of-transfusion",
        element: <Transfusion />,
      },
      {
        path: "/test",
        element: <Test />,
      },
      {
        path: "/journal",
        element: <Journal />,
      },
      {
        path: "/current-affairs",
        element: <CurrentAffairs />,
      },
      {
        path: "/ict",
        element: <ICT />,
      },
      {
        path: "/photo-gallery",
        element: <PhotoGallery />,
      },
      {
        path: "/video-gallery",
        element: <VideoGallery />,
      },
      {
        path: "/notice",
        element: <Notice />,
      },
      {
        path: "/emergency-notice",
        element: <EmergencyNotice />,
      },
      {
        path: "/site-selection/:id",
        element: <Subject />,
      },
      {
        path: "/gallery/:id",
        element: <GalleryDetails />,
      },

      {
        path: "/video-gallery/:id",
        element: <VideoGalleryDetails />,
      },
      {
        path: "/people/:id",
        element: <People />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
export default router;
