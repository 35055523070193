import { Spinner } from "@material-tailwind/react";
import logo from '../../assets/images/logo/logo2.png'
 
export default function CustomSpinner() {
  return (
    <div className="h-screen w-full flex justify-center items-center">
        <div className="relative">
        <Spinner color="green" className="h-32 w-32 text-red-700" />
      
        </div>
        <div className="absolute">
            <img className="w-18 h-20" src={logo} alt="" />
        </div>
    </div>
  );
}