import { Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { FaChevronRight } from "react-icons/fa";

const allData = [
  {
    en: "To provide facility for community and hospital based training for all level of laboratory personnel.",
    bn: "সকল স্তরের পরীক্ষাগার কর্মীদের জন্য কমিউনিটি এবং হাসপাতাল ভিত্তিক প্রশিক্ষণের সুবিধা প্রদান করা।",
  },
  {
    en: "To make a network all over the country to ensure quality of laboratory services.",
    bn: "ল্যাবরেটরি সেবার মান নিশ্চিত করতে সারাদেশে নেটওয়ার্ক তৈরি করা।",
  },
  {
    en: "Provide pathological service and utilize the institute as referral laboratory.",
    bn: "প্যাথলজিক্যাল সেবা প্রদান এবং ইনস্টিটিউটকে রেফারেল ল্যাবরেটরি হিসেবে ব্যবহার করা।",
  },
  {
    en: "Introduce accreditation in the field of laboratory medicine.",
    bn: "পরীক্ষাগার ওষুধের ক্ষেত্রে স্বীকৃতি প্রবর্তন করন।",
  },
  {
    en: "Conduct Post-graduate and Undergraduate courses on different subjects of Laboratory Medicine.",
    bn: "ল্যাবরেটরি মেডিসিনের বিভিন্ন বিষয়ে স্নাতকোত্তর  এবং স্নাতক কোর্স পরিচালনা করন।",
  },

  {
    en: "Conduct research activities.",
    bn: "গবেষণা কার্যক্রম পরিচালনা।",
  },
  {
    en: "Controlling the quality of laboratory tests.",
    bn: "ল্যাবরেটরী টেস্টের মান নিয়ন্ত্রণ করা।",
  },
  {
    en: "Generate income through service provision.",
    bn: "সেবা প্রদানের মাধ্যমে রাজস্ব আয় করা।",
  },
];

const MissionVission = () => {
  const lang = localStorage.getItem("language");
  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Mission & Vision" : "মিশন ও ভিশন"}
          </Typography>
        </Card>
        <div className="mt-2">
          <Card className="w-full h-full rounded-sm">
            <CardBody className="p-2">
              <div className="flex flex-col gap-5">
                {allData.map((data, index) => {
                  return (
                    <div className="flex items-start gap-2 h-8" key={index}>
                      <FaChevronRight className="mt-1 text-green-600" />
                      <Typography className="w-full text-black font-medium">
                        {lang === "en" ? data?.en : data?.bn}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MissionVission;
