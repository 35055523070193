import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { server } from "../server";
import Loader from "../components/shared/Loader";
import { format } from "date-fns";
import { bn } from "date-fns/locale";
import { MdCalendarMonth } from "react-icons/md";

const PhotoGallery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");
  const [sortedData, setSortedData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Sort the data by date when the data changes
    const sorted = data
      .slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    setSortedData(sorted);
  }, [data]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/gallery/get-gallery`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.gallery);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }

  const formatDate = (dateString, lang) => {
    const date = new Date(dateString);
    const formatOptions = { day: "numeric", month: "long", year: "numeric" };

    if (lang === "bn") {
      // Convert each digit of the year to Bangla numeral
      const banglaNumerals = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
      const banglaYear = date
        .getFullYear()
        .toString()
        .replace(/\d/g, (digit) => banglaNumerals[digit]);

      return format(date, `do  MMMM, ${banglaYear}`, { locale: bn });
    } else {
      return date.toLocaleDateString("en-US", formatOptions);
    }
  };

  return (
    <div>
      <div className="px-0 lg:px-2 mt-2 relative">
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Photo Gallery" : "ফটো গ্যালারী"}
          </Typography>
        </Card>
        <hr className="my-3" />
        <div>
          {sortedData?.slice(0, showAll ? sortedData.length : 8).map((data) => {
            return (
              <div key={data._id}>
                <Link to={`/gallery/${data._id}`}>
                  <Card className="rounded mb-2 hover:bg-red-600 hover:text-white">
                    <CardBody className="flex p-0 flex-row lg:justify-between items-center gap-4 ">
                      <div className=" w-40 h-28 basis-5/12 lg:basis-1/4">
                        <img
                          className="w-full block rounded-l h-full object-cover object-bottom"
                          src={data?.thumbnail?.url}
                          alt=""
                        />
                      </div>
                      <div className="lg:w-[calc(100%-170px)] pr-2 basis-7/12 lg:basis-3/4">
                        <div className="flex items-start flex-col lg:flex-row justify-between ">
                          <div className="">
                            <Typography className="font-bold lg:w-72">
                              {lang === "en"
                                ? data.title.en.length > 30
                                  ? data.title.en.slice(0, 30) + "..."
                                  : data.title.en
                                : data.title.bn.length > 30
                                ? data.title.bn.slice(0, 25) + '...'
                                : data.title.bn}
                            </Typography>
                          </div>
                          <div className="">
                            <Typography className="text-xs flex items-center gap-1">
                             <MdCalendarMonth /> {formatDate(data.date, lang)}
                            </Typography>
                          </div>
                        </div>
                        <div>
                          <Typography className="text-sm text-justify h-[60px]  hidden lg:block">
                            {lang === "en"
                              ? data?.description.en.length > 120
                                ? data?.description.en.slice(0, 120) + "..."
                                : data?.description.en
                              : data?.description.bn.length > 100
                              ? data?.description.bn.slice(0, 100) + "..."
                              : data?.description.bn}
                      
                          </Typography>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </div>
            );
          })}
          {!showAll && sortedData.length > 10 && (
            <Button
              className="rounded-sm my-2"
              color="green"
              size="sm"
              fullWidth
              onClick={() => setShowAll(true)}
            >
              {lang === "en" ? "Show All" : "সব দেখুন"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
