import React from "react";
import foo_img from "../../assets/images/footer/footer.png";
import { Typography } from "@material-tailwind/react";

const Footer = () => {
  const lang = localStorage.getItem("language");

  return (
    <div className="">
      <img src={foo_img} alt="" />
      <div className="flex flex-col lg:flex-row justify-between bg-gray-200 p-4">
        <div>
          <Typography className="font-semibold text-md text-gray-800">
            {lang === "en"
              ? "National Institute of Laboratory Medicine & Referral Center"
              : "ন্যাশনাল ইনস্টিটিউট অব ল্যাবরেটরী মেডিসিন এন্ড রেফারেল সেন্টার"}
          </Typography>
          <Typography className="text-sm text-gray-900">
            {lang === "en"
              ? "Plot #F/17-A, Sher-E-Bangla Nagar, Dhaka-1207"
              : "প্লট # এফ/১৭-এ, ‍শের-ই-বাংলা নগর, ঢাকা-১২০৭"}
          </Typography>
          <Typography className="text-sm text-gray-900">
            {lang === "en"
              ? "01911-909153, 8802-44827350"
              : " ০১৯১১-৯০৯১৫৩,৮৮০২-৪৪৮২৭৩৫০"}
          </Typography>
          <Typography className="text-sm text-gray-900">
            <a href="mailto:nilmrc@ld.dghs.gov.bd">nilmrc@ld.dghs.gov.bd</a>
          </Typography>
        </div>
        <div>
          <Typography className="font-semibold text-md text-gray-800">
            Developed by
          </Typography>
          <a
            className="text-gray-900 hover:text-blue-800"
            target="_blank"
            href="https://www.horizonsolutions.tech/"
          >
            <Typography className="text-sm ">Horizon Rising Star</Typography>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
