import { Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";

const ReportDeliveryTime = () => {
  const lang = localStorage.getItem("language");
  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Report Delivery Time" : "রিপোর্ট ডেলিভারি সময়"}
          </Typography>
        </Card>
        <div className="mt-2">
          <Card className="w-full h-48 flex justify-center items-center rounded-sm">
            <CardBody className="p-2">
              <Typography className="text-xl font-bold text-green-600">
                {lang === "en"
                  ? "From 12.00 PM to 02.30 PM"
                  : " দুপুর ১২:০০ টা থেকে বেলা ২:৩০ মিনিট"}
              </Typography>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ReportDeliveryTime;
