import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from '@material-tailwind/react'
import { server } from '../../server'
import { Link } from 'react-router-dom'


const RightNav = () => {
    const lang = localStorage.getItem('language')
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await fetch(`${server}/sidebar/get-sidebar`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData.sidebar);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const rightNav = data.filter(d => d.side === "Right")
    rightNav.sort(function (a, b) {
        return a.serial - b.serial;
    });
    return (
        <div>
            <div className='flex flex-col gap-8 w-full my-0 lg:my-8'>
                {
                    rightNav.map(data => {
                        return (
                            <div key={data._id} className=''>
                                <Card className='p-0 rounded-sm w-full lg:w-48'>
                                    <CardHeader
                                        color="green"
                                        className="mb-0 grid shadow-none h-8 w-full mx-auto font-bold text-sm place-items-center rounded-t-sm rounded-b-none">
                                        {lang === 'en' ? data.designation.en : data.designation.bn}
                                    </CardHeader>
                                    <CardBody className='p-2 flex flex-col items-center '>
                                        <img className='w-36 ' src={data.avatar.url} alt="" />
                                        <a href="" className='text-xs text-black my-2 text-center'> {lang === 'en' ? data.name.en : data.name.bn}</a>
                                        <p className='text-xs text-center text-black'> {lang === 'en' ? data.institution.en : data.institution.bn}</p>
                                        <Link to={`people/${data._id}`} className='text-xs mt-2 hover:text-blue-700 hover:underline text-black'>{lang === 'en' ? 'Details' : 'বিস্তারিত'}</Link>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default RightNav
