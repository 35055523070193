import { Card, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import img from "../assets/images/pages/6.jpg";
import { server } from "../server";
import { BiSolidDownload } from "react-icons/bi";

const Journal = () => {
  const TABLE_HEAD = [
    {
      en: "Sl",
      bn: "ক্রম",
    },
    {
      en: "Subject",
      bn: "বিষয়বস্তু",
    },
    {
      en: "Download",
      bn: "ডাউনলোড",
    },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/journal/get-journal`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData?.journal);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="px-0 lg:px-2 mt-2">
        <Card className="rounded-sm w-full">
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Journal Publications" : "জার্নাল পাবলিকেশন্স"}
          </Typography>
        </Card>
        <img className="mx-auto w-4/5" src={img} alt="" />
        <hr className="my-3" />
        {/* <Typography variant="paragraph" className="text-justify leading-loose">
          ন্যাশনাল ইনস্টিটিউট অব ল্যাবরেটরী মেডিসিন এন্ড রেফারেল সেন্টার
          (NILMRC) হল একটি সরকারি সংস্থা যা বাংলাদেশের ঢাকায় অবস্থিত। এটি ২০১৮
          সালে প্রতিষ্ঠিত হয়েছিল এবং এর প্রধান কার্যালয় আগারগাঁও, ঢাকায়
          অবস্থিত। NILMRC এর লক্ষ্য হল বাংলাদেশের রোগনির্ণায়ক পরীক্ষার মান
          উন্নত করা এবং রোগ নির্ণয়, চিকিৎসা এবং রোগ নিয়ন্ত্রণে সহায়তা করা।
          NILMRC বিভিন্ন ধরনের রোগনির্ণায়ক পরীক্ষা পরিচালনা করে, যার মধ্যে
          রয়েছে রক্ত পরীক্ষা, মূত্র পরীক্ষা, মল পরীক্ষা, টিস্যু পরীক্ষা এবং
          ব্যাকটেরিয়া পরীক্ষা। NILMRC এছাড়াও রোগ নির্ণয় এবং চিকিৎসার ক্ষেত্রে
          প্রশিক্ষণ প্রদান করে এবং রোগ নিয়ন্ত্রণের জন্য গবেষণা করে।
        </Typography> */}

        <div>
          <Card className="h-full w-full overflow-scroll lg:overflow-hidden rounded-sm">
            <table className="w-full table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {lang === "en" ? head.en : head.bn}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map(({ _id, link, title }, index) => {
                  return (
                    <tr key={_id} className="even:bg-blue-gray-50/50 ">
                      <td className="p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm"
                        >
                          {index + 1}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-justify ml-4"
                        >
                          {lang === "en" ? title.en : title.bn}
                        </Typography>
                      </td>
                      <td>
                        <a href={link} target="_blank" rel="noreferrer">
                          <BiSolidDownload className="bg-green-500 text-white text-2xl rounded-sm ml-8" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Journal;
