import { Card, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { server } from "../../server";

const NumOfEmp = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/staffInfo/get-staffinfo`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.staffInfo);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const convertToBengaliNumerals = (number) => {
    const arabicNumerals = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const bengaliNumerals = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

    return number
      .toString()
      .replace(
        /\d/g,
        (digit) => bengaliNumerals[arabicNumerals.indexOf(digit)]
      );
  };

  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en"
              ? `Total Number of Officers/Employees : ${data?.length>0? data?.length: ""}`
              : `সকল কর্মকর্তা / কর্মচারীদের সংখ্যা : ${data?.length>0? convertToBengaliNumerals(data?.length) : ""}`}
          </Typography>
        </Card>
      </div>
      <div>
        <div className="relative mt-2 ">
          <img
            className="rounded-md mx-auto"
            src="https://img.freepik.com/free-vector/doctors-with-stethoscopes-nurses-standing-together-smiling-group-female-male-medical-staff-hospital-emergency-team-clinic-flat-vector-illustration-medicine-healthcare-concept_74855-21072.jpg"
            alt=""
          />
        </div>
        {
          <div className="relative bottom-20 bg-green-400 bg-opacity-50 p-4 rounded-sm w-32 mx-auto">
            <p className="  text-center text-5xl font-extrabold text-red-900 font-mon leading-6 ">
              <span className=" text-xl ">
                {lang === "en"
                  ? `${data?.length} people`
                  : `${convertToBengaliNumerals(data?.length)} জন`}
              </span>{" "}
            </p>
          </div>
        }
      </div>
    </div>
  );
};

export default NumOfEmp;
