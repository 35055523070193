import { Button, Card, Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/404/1.png";

const ErrorPage = () => {
  const lang = localStorage.getItem("language");

  return (
    <div className="">
      <div className="px-0 lg:px-2 mt-2 ">
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
           {lang === 'en' ? "Status" : "স্ট্যাটাস"}
          </Typography>
        </Card>
        <hr className="my-3" />
        <div className="">
          {/* <div className="">
            <img className="mx-auto" src={img} alt="" />
          </div> */}
          <div className=" flex justify-center items-center flex-col gap-4">
            <p className="text-red-600 font-bold text-2xl">
            {lang === 'en' ? "Coming soon..." : "শীঘ্রই আসছে"}
               </p>
            <Link to="/">
              <Button className="bg-[#4caf50]" color="green">
              {lang === 'en' ? "Back to home" : "হোমে ফিরুন"}
                </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
