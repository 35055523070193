import { Card, Typography } from '@material-tailwind/react'
import img from "../assets/images/pages/5.png"
import React from 'react'

const Notice = () => {
    const TABLE_HEAD = ["ক্রম", "বিষয়বস্তু", "ইস্যু নম্বর", "শাখা", "প্রকাশের তারিখ", "ডাউনলোড"];
    const TABLE_ROWS = [
        {
            id: "১",
            name: "ই-জিপির মাধ্যমে দরপত্র বিজ্ঞপ্তি",
            issue_no: "৩০৪",
            div: "প্রশাসনিক",
            time: "০৯-০৩-২০২৩",
            title: "দরপত্র বিজ্ঞপ্তি.pdf",
            link: 'https://drive.google.com/file/d/1pis1nsOj1jzq-R-m1X9DrEWRjBteFK8l/view'
        },

    ];

    return (
        <div>
            <div className='px-0 lg:px-2 mt-2'>
                <Card className='rounded-sm w-full'> <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">নোটিশ বোর্ড</Typography></Card>
                <img className='mx-auto w-3/5' src={img} alt="" />
                <hr className='my-3' />
                <div>
                    <Card className="h-full w-full overflow-scroll lg:overflow-hidden rounded-sm">
                        <table className="w-full table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {TABLE_ROWS.map(({ id, name, issue_no, div, link, time, title }, index) => {
                                    const isLast = index === TABLE_ROWS.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={name}>
                                            <td className={classes}>

                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {id}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {name}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} bg-blue-gray-50/50`}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {issue_no}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {div}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {time}
                                                </Typography>
                                            </td>
                                            <a href={link}> <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal  hover:text-blue-800">
                                                    {title}
                                                </Typography>
                                            </td></a>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Notice
