import { Card, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import img from "../assets/images/pages/3.png";
import { useParams } from "react-router-dom";
import { server } from "../server";

const NOC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    // Sort the data by date when the data changes
    const sorted = data.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    setSortedData(sorted);
  }, [data]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/noc/get-noc`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.noc);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  const TABLE_HEAD = ["Month", "Date", "Name of NOC"];

  return (
    <div>
      <div className="px-0 lg:px-2 mt-2">
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "NOC" : " এনওসি"}
          </Typography>
        </Card>
        <img className="mx-auto w-3/5" src={img} alt="" />
        <hr className="my-3" />
        <div>
          <Card className="h-full w-full rounded-sm overflow-scroll lg:overflow-hidden ">
            <table className="w-full  table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedData?.map(({ _id, name, date, date2, link }) => (
                  <tr key={_id} className="even:bg-blue-gray-50/50">
                    <td className="p-4">
                      <Typography
                        variant="normal"
                        color="green"
                        className="font-normal text-sm"
                      >
                        {date2}
                      </Typography>
                    </td>
                    <td className="p-4 flex flex-col ">
                      <td>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {date.slice(0, 10)}
                        </Typography>
                      </td>
                    </td>
                    <td className="p-4 ">
                      <td className="flex flex-col">
                        <a target="_blank" href={link}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-medium hover:underline hover:underline-offset-4 hover:text-red-500"
                          >
                            {lang === "en" ? name.en : name.bn}
                          </Typography>
                        </a>
                      </td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default NOC;
